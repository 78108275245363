<template>
  <span class="text">
    <slot />
  </span>
</template>

<style lang="scss" scoped>
.text,
.text :deep(*) {
  @apply tw-font-sans tw-text-xs tw-font-normal tw-leading-4 tw-text-gray-500;
}
</style>
